import { useState, useMemo, useEffect } from 'react';
import { refHandler } from 'app/utils/helper-functions';
import { AddComment } from 'app/components/editor/sidebar/comments/AddComment';
import { StyledElement } from 'app/slate/components/StyledElement';
import { AddStyledParagraph } from 'app/components/editor/sidebar/styledparagrah/AddStyledParagraph';
import { centeredPopperElement } from 'app/utils/helper-functions';
import { usePopper } from 'react-popper';
import { Portal } from '../../components/editor/sidebar/Portal';
import { useEditorSelector } from 'app/state/contexts/EditorContext';

import { useSlate } from 'slate-react';
import { Editor } from 'slate';
export const Paragraph = ({ element, attributes, children, withComments, withStyledParagrah }) => {
  const [ref, setRef] = useState(null);
  const [isMultipleParagraphsSelected, setIsMultipleParagraphsSelected] = useState(false);
  const editor = useSlate();

  useEffect(() => {
    const checkMultipleParagraphsSelected = () => {
      const isSelected =
        Array.from(
          Editor.nodes(editor, {
            at: editor.selection,
            match: (n) => Editor.isBlock(editor, n) && n.type === 'paragraph',
          })
        ).length > 1;
      setIsMultipleParagraphsSelected(isSelected);
    };

    checkMultipleParagraphsSelected();
  }, [editor, editor.selection]);

  const anchor = `anchor-${element.uuid}`;

  return (
    <div {...attributes} ref={refHandler(attributes, setRef)}>
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <a contentEditable="false" href={`#${anchor}`} id={anchor}></a>

      <SeletedTextWrapper popperRef={ref} element={element}>
        <>
          {withComments && !isMultipleParagraphsSelected && ref ? <AddComment element={element} /> : null}
          {withStyledParagrah && ref ? <AddStyledParagraph /> : null}
        </>
      </SeletedTextWrapper>

      <StyledElement element={element} attributes={attributes} children={children} />
    </div>
  );
};

export const SeletedTextWrapper = ({ children, popperRef, element }) => {
  const [popperElement, setPopperElement] = useState(null);
  const referenceElement = useMemo(() => centeredPopperElement(popperRef), [popperRef]);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right-end',
  });
  const [show, setShow] = useState(true);
  const selectionId = useEditorSelector((editor) => editor.selectionId);

  useEffect(() => {
    setShow(selectionId && selectionId === element.uuid);
  }, [element.uuid, selectionId]);
  return (
    <>
      {show && (
        <Portal>
          <div ref={setPopperElement} style={{ ...styles.popper, gap: '2px', display: 'flex', flexDirection: 'column' }} {...attributes.popper}>
            {children}
          </div>
        </Portal>
      )}
    </>
  );
};
