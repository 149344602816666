import ServerErrorHandler from 'app/ErrorHandler';
import request from 'app/api/request';
import { showMessage } from 'app/utils/messages';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import { queryKeys as documentQueryKeys } from './documents/document-queries';
import { i18n } from 'app/utils/i18n';

export const queryKeys = {
  style: 'STYLE',
  styles: 'STYLES',
  css: 'CSS',
};

export function useStyle({ organization, documentSuperId, styleId }) {
  return useQuery(
    [queryKeys.style, organization, documentSuperId, styleId],
    async () => {
      const res = await request.get(`/gaby/styles/${styleId}?parsed=true`);
      return res.data;
    },
    {
      enabled: !!styleId,
      onError: ServerErrorHandler,
    }
  );
}

export function useStyles({ organization, documentSuperId, styleIds }) {
  function makeRequests() {
    return styleIds.map(async (styleId) => {
      const res = await request.get(`/gaby/styles/${styleId}?parsed=true`);
      return res.data;
    });
  }
  return useQuery(
    [queryKeys.styles, organization, documentSuperId, ...styleIds],
    async () => {
      return await Promise.all(makeRequests());
    },
    {
      enabled: !!styleIds.length,
      onError: ServerErrorHandler,
    }
  );
}

export function useCss({ documentSuperId, documentRevisionId, useDraft, withStyle, embedd }) {
  return useQuery(
    [queryKeys.css, documentSuperId, documentRevisionId, { useDraft, withStyle, embedd }],
    async () => {
      const queryParams = [];
      if (useDraft) {
        queryParams.push('useDraft=true');
      }
      if (withStyle) {
        queryParams.push('withStyle=' + withStyle);
      }
      if (embedd) {
        queryParams.push('embedd=true');
      }
      if (documentRevisionId) {
        queryParams.push('revisionId=' + documentRevisionId);
      }
      const query = '?' + queryParams.join('&');
      const response = await request.get(`/gaby/documents/${documentSuperId}/css${query}`);
      return response.data;
    },
    { enabled: !!documentSuperId, onError: ServerErrorHandler }
  );
}

export function useSaveStyle({ organization, documentSuperId, templateSuperId, pageId }) {
  const queryClient = useQueryClient();
  return useMutation(
    async (content) => {
      const styleId = uuidv4();

      const action = {
        action: 'update_page_style',
        data: {
          styleId,
          organization,
          documentSuperId,
          pageId,
          templateSuperId,
          content,
        },
      };
      return await request.post(`/gaby/documents/${documentSuperId}/actions`, action);
    },
    {
      onError: ServerErrorHandler,
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.css);
        queryClient.invalidateQueries(queryKeys.styles);
      },
    }
  );
}

export function useSaveFontStyle({ documentSuperId, styleId }) {
  const queryClient = useQueryClient();
  return useMutation(
    async (content) => {
      const action = {
        action: 'update_font_style',
        styleId,
        data: {
          content,
        },
      };
      return await request.post(`/gaby/documents/${documentSuperId}/actions`, action);
    },
    {
      onError: ServerErrorHandler,
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.css);
        queryClient.invalidateQueries(documentQueryKeys.document, documentSuperId);
        showMessage('success', i18n('project-details.font-updated'));
      },
    }
  );
}

export function useDeleteFontStyle({ documentSuperId, styleId }) {
  const queryClient = useQueryClient();
  return useMutation(
    async () => {
      const action = {
        action: 'delete_font_style',
        styleId,
      };
      return await request.post(`/gaby/documents/${documentSuperId}/actions`, action);
    },
    {
      onError: ServerErrorHandler,
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.css);
        queryClient.invalidateQueries(documentQueryKeys.document, documentSuperId);
        showMessage('success', i18n('project-details.font-deleted'));
      },
    }
  );
}
