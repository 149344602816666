import React, { useState, useReducer, useEffect } from 'react';
import { Transforms, Editor as SlateEditor } from 'slate';
import { useSlate } from 'slate-react';
import { Loader } from 'app/components';
import { useEditorSelector } from 'app/state/contexts/EditorContext';
import { v4 as uuidv4 } from 'uuid';
import { useGetDocumentTableOfContent } from 'app/api/documents/document-queries';
import { i18n } from 'app/utils/i18n';
import { Dropdown, FilterButton } from 'app/components';

const levels = ['h1', 'h2', 'h3', 'h4'];
const startValues = ['DOCUMENT_START', 'TOC_POSITION', 'MANUAL'];
const stopValues = ['DOCUMENT_END', 'TOC_NEXT', 'MANUAL'];

export const ImportToC = ({ onClose, uuid, defaultSettings = {} }) => {
  const editor = useSlate();
  const reduxActiveNode = useEditorSelector((editor) => editor.activeNode);
  const pDocument = useEditorSelector((editor) => editor.pDocument);
  const locale = useEditorSelector((editor) => editor.locale);

  const [selectedLevels, setSelectedLevels] = useState(defaultSettings.levels || levels);
  const [selectedStartType, setSelectedStartType] = useState(getStartType(defaultSettings));
  const [selectedStartManual, setSelectedStartManual] = useState(getManualStart(defaultSettings));
  const [selectedStopType, setSelectedStopType] = useState(getStopType(defaultSettings));
  const [selectedStopManual, setSelectedStopManual] = useState(getManualStop(defaultSettings));
  const [overrides, updateOverride] = useReducer(overrideReducer, defaultSettings.overrides || []);

  const settings = {
    start: getStart(selectedStartType, selectedStartManual),
    stop: getStop(selectedStopType, selectedStopManual),
    levels: selectedLevels,
    overrides,
  };

  const { data } = useGetDocumentTableOfContent(pDocument.revisionId, {
    start: settings.start,
    stop: settings.stop,
    currentPosition: settings.uuid || reduxActiveNode.uuid,
    headerHierarchy: selectedLevels,
    locale,
  });

  const startItems = startValues.map((start) => ({
    title: i18n(`toc.settings.start.${start}`),
    value: start,
  }));

  const stopItems = stopValues.map((stop) => ({
    title: i18n(`toc.settings.stop.${stop}`),
    value: stop,
  }));

  useEffect(() => {
    if (selectedStopType === 'MANUAL') {
      setSelectedStopManual(data.range.stop);
    }
  }, [selectedStopType, setSelectedStopManual, data?.range.stop]);

  const handleInsert = () => {
    const slateTableOfContent = [
      {
        type: 'table-of-content',
        uuid: uuid || uuidv4(),
        revisionId: uuidv4(),
        previousRevisionId: null,
        children: [{ text: '' }],
        settings,
      },
      { type: 'paragraph', children: [{ text: ' ' }] },
    ];

    const point = SlateEditor.end(editor, editor.lastSelection);

    if (point) {
      Transforms.insertNodes(editor, slateTableOfContent, { at: point });
      Transforms.removeNodes(editor, { at: point });
    }

    onClose();
  };

  const handleFilter = (changeEvent) => {
    const { checked, value } = changeEvent;

    if (checked) {
      setSelectedLevels((selected) => [...selected, value]);
    } else {
      setSelectedLevels((selected) => selected.filter((s) => s !== value));
    }
  };

  const handleStartManual = (e) => {
    setSelectedStartManual(e.target.value);
  };

  const handleEndManual = (e) => {
    setSelectedStopManual(e.target.value);
  };

  if (!data?.content) {
    return <Loader />;
  }

  return (
    <>
      <div>
        <h2 className="subheading text-blue-600">{i18n(`toc.hierarchy.levels`)}</h2>
        <div className="d-flex gap-2">
          {levels.map((level) => (
            <FilterButton
              key={level}
              title={i18n(`toc.hierarchy.level.${level}`)}
              checked={selectedLevels.includes(level)}
              value={level}
              onChange={handleFilter}
            />
          ))}
        </div>
      </div>
      <hr className="my-5 border-gray-300" />
      <div>
        <h2 className="subheading text-blue-600 mt-4 mb-4">{i18n(`toc.hierarchy.span`)}</h2>
        <div className="row mb-3">
          <RangeSelector
            items={startItems}
            labelSuffix="start"
            onTypeChange={setSelectedStartType}
            onManualChange={handleStartManual}
            typeValue={selectedStartType}
            manualValue={settings.start}
            rangeValue={data.range.start}
          />
        </div>
        <div className="row">
          <RangeSelector
            items={stopItems}
            labelSuffix="stop"
            onTypeChange={setSelectedStopType}
            onManualChange={handleEndManual}
            typeValue={selectedStopType}
            manualValue={settings.stop}
            rangeValue={data.range.stop}
          />
        </div>
      </div>
      <hr className="my-5 border-gray-300" />
      <div className="mt-5">
        <HeaderList settings={settings} headers={data.content} selectedLevels={selectedLevels} updateOverride={updateOverride} />
      </div>
      <div className="d-flex justify-content-end mt-5">
        <button className="btn" onClick={onClose}>
          {i18n('common.button.cancel')}
        </button>
        <button className="btn btn-primary" onClick={handleInsert}>
          {i18n('toc.settings.insert')}
        </button>
      </div>
    </>
  );
};

const RangeSelector = ({ items, labelSuffix, onTypeChange, onManualChange, typeValue, manualValue, rangeValue }) => (
  <>
    <div className="col align-items-stretch d-flex flex-column">
      <label className="form-label">{i18n(`toc.hierarchy.span.${labelSuffix}`)}</label>
      <Dropdown items={items} onChange={onTypeChange} value={typeValue} />
    </div>
    <div className="col-3">
      <label className="form-label">{i18n(`toc.hierarchy.span.pagenumber`)}</label>
      <input
        className="form-control"
        disabled={typeValue !== 'MANUAL'}
        type="number"
        value={typeValue === 'MANUAL' && manualValue ? manualValue : rangeValue}
        onChange={onManualChange}
      />
    </div>
  </>
);
const HeaderList = ({ selectedLevels, updateOverride, settings, headers }) => {
  return (
    <>
      <div className="row align-items-center mb-3">
        <div className="col-6">
          <h3 className="subheading text-blue-600">{i18n(`toc.hierarchy.headers`)}</h3>
        </div>
        <div className="col-6">
          <h3 className="subheading text-blue-600">{i18n(`toc.hierarchy.override`)}</h3>
        </div>
      </div>
      {headers.map((header, idx) => (
        <HeaderItem
          key={idx}
          headers={headers}
          header={header}
          selectedLevels={selectedLevels}
          overrides={settings.overrides}
          updateOverride={updateOverride}
          {...header}
        />
      ))}
    </>
  );
};

const HeaderItem = ({ selectedLevels, header, headers, updateOverride, overrides, parentExcluded = false }) => {
  const existingOverride = overrides.find((override) => override.uuid === header.uuid);
  const defaultOverride = overrides?.find((override) => header.uuid === override.uuid)?.text || '';
  const initialIsExcluded = existingOverride ? !!existingOverride.excluded : false;

  const [overrideName, setOverrideName] = useState(defaultOverride);
  const [isExcluded, setIsExcluded] = useState(initialIsExcluded);

  const handleOverrideName = (e) => {
    const text = e.currentTarget.value;
    setOverrideName(text);
    updateOverride({ uuid: header.uuid, revisionId: header.revisionId, text: text !== '' ? text : null });
  };

  const handleExcludeChange = (e) => {
    const excluded = !e.target.checked;
    setIsExcluded(excluded);
    updateOverride({ uuid: header.uuid, revisionId: header.revisionId, excluded: excluded, text: overrideName !== '' ? overrideName : null });
  };

  return (
    <>
      {selectedLevels.includes(header.type) ? (
        <>
          {isFirstOfPage(headers, header, selectedLevels) ? (
            <div className="row mt-3">
              <div className="col-12">
                <div className="fs-7 mb-1 fw-bolder text-blue-800">{i18n(`toc.hierarchy.page-number`) + ' ' + header.pageNumber}</div>
              </div>
            </div>
          ) : null}
          <div className="row align-items-center mb-2">
            <div className="col-6 d-flex align-items-center">
              <input
                type="checkbox"
                className="form-check-input ms-0 me-3 mt-0 flex-shrink-0"
                checked={!isExcluded && !parentExcluded}
                onChange={handleExcludeChange}
                role="switch"
              />
              <div className={isExcluded || parentExcluded ? 'text-decoration-line-through' : ''}>
                {header.name || i18n('toc.settings.header.no-permission')}
                <div className="text-blue-600">{i18n(`toc.hierarchy.level.${header.type}`)}</div>
              </div>
            </div>
            <div className="col-6">
              <input type="text" className="form-control" disabled={isExcluded || parentExcluded} value={overrideName} onChange={handleOverrideName} />
            </div>
          </div>
        </>
      ) : null}
      {header.children.map((child, idx) => (
        <HeaderItem
          key={idx}
          selectedLevels={selectedLevels}
          updateOverride={updateOverride}
          headers={headers}
          header={child}
          overrides={overrides}
          parentExcluded={isExcluded || parentExcluded}
        />
      ))}
    </>
  );
};

const overrideReducer = (overrides, action) => {
  const { uuid, text, excluded } = action;
  const otherOverrides = overrides.filter((override) => override.uuid !== uuid);

  if (text === undefined && excluded === undefined) {
    return otherOverrides;
  } else {
    const newOverride = { uuid, ...(text !== undefined && { text }), ...(excluded !== undefined && { excluded }) };
    return [...otherOverrides, newOverride];
  }
};

const isFirstOfPage = (headers, header, selectedLevels) => {
  const pageNumber = header.pageNumber;
  const flatHeaders = headers.flatMap(flatten);
  const filteredHeaders = flatHeaders.filter((header) => selectedLevels.includes(header.type));
  const previousIndex = filteredHeaders.findIndex((fh) => fh === header) - 1;
  const previousPageNumber = filteredHeaders[previousIndex]?.pageNumber;

  return pageNumber !== previousPageNumber;
};

const flatten = (header) => [header, ...header.children.flatMap(flatten)];

const getStart = (start, startManual) => {
  return start !== 'MANUAL' ? start : startManual;
};
const getStop = (stop, stopManual) => {
  return stop !== 'MANUAL' ? stop : stopManual;
};

const getStartType = (settings) => {
  if (!settings.start) {
    return 'DOCUMENT_START';
  }
  return isNaN(settings.start) ? settings.start : 'MANUAL';
};

const getStopType = (settings) => {
  if (!settings.start) {
    return 'DOCUMENT_END';
  }
  return isNaN(settings.stop) ? settings.stop : 'MANUAL';
};

const getManualStart = (settings, defaultValue = 1) => {
  return isNaN(settings.start) ? defaultValue : settings.start;
};

const getManualStop = (settings) => {
  return isNaN(settings.stop) ? '' : settings.stop;
};
