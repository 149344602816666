import { useEditorSelector } from 'app/state/contexts/EditorContext';
import { useComments } from 'app/api/useComments';
import { useState, useContext, useRef, useLayoutEffect, useEffect, useCallback } from 'react';
import { EditorContext } from 'app/state/contexts/EditorContext';
import { SidebarContext } from 'app/state/contexts/SidebarContext';
import { UserContext } from '../../../../state/contexts/UserContext';
import { MentionInput } from '../../../MentionInput';
import { v4 as uuidv4 } from 'uuid';
import { useEditorDispatch } from 'app/state/contexts/EditorContext';
import { setIsDirty } from 'app/state/redux/documentSlice';
import { useDelayedSave } from 'app/utils/hooks/delayed-save';
import { CommentHeader } from './CommentHeader';
import { Response } from './Response';
import { i18n } from 'app/utils/i18n';
import { navigateTo } from 'app/slate/utils';

export const Comment = ({ comment, responses, activeResponse, setActiveResponse }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentComment, setCurrentComment] = useState(comment);
  const [isUpdating, setIsUpdating] = useState(false);
  const [responseComment, setResponseComment] = useState(null);
  const [showResponses, setShowResponses] = useState(false);
  const inputRef = useRef(null);
  const { superId: sectionSuperId } = useEditorSelector((editor) => editor.section);
  const { setDraftComment, draftComment, setActiveComment, activeComment } = useContext(SidebarContext);
  const { user } = useContext(UserContext);
  const { shadowHost } = useContext(EditorContext);
  const { deleteComment, updateCommentStatus, publishComment, createCommentAsync, updateCommentMessageAsync, refetchComments } = useComments(sectionSuperId);
  const editorDispatch = useEditorDispatch();

  const isActiveComment = activeComment?.id === comment.id;
  const isResponse = comment.onType === 'comment';
  const isNewComment = draftComment?.id === comment.id;
  const isUniqueText = shadowHost.shadowRoot.querySelectorAll(`[comment-id="${comment.id}"]`).length === 1;
  const generateResponseComment = useCallback(
    () => ({
      id: uuidv4(),
      userId: user.id,
      onId: comment.id,
      onType: 'comment',
      citation: '',
      message: '',
      sectionSuperId,
      createdAt: new Date(),
      updatedAt: null,
      publishedAt: null,
      user: {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      },
    }),
    [comment.id, sectionSuperId, user]
  );

  useEffect(() => {
    setResponseComment(generateResponseComment);
  }, [comment.id, generateResponseComment]);

  const handleDelete = () => {
    const element = shadowHost.shadowRoot.querySelector(`#anchor-${comment.onId}`);
    element?.parentElement?.classList.remove('layout-leaf-comment');
    deleteComment(comment.id);
  };

  useLayoutEffect(() => {
    if (isNewComment && !comment.updatedAt && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.selectionStart = inputRef.current.value.length;
    }
  }, [comment.publishedAt, comment.updatedAt, isNewComment, comment]);

  const saveComment = async (message) => {
    editorDispatch(setIsDirty(true));
    if (comment.updatedAt) {
      await updateCommentMessageAsync({ commentId: comment.id, message: currentComment.message });
    } else {
      const newComment = { ...comment, message: currentComment.message, publishedAt: null };
      await createCommentAsync({ sectionSuperId, comment: newComment });
    }
    editorDispatch(setIsDirty(false));
  };
  const [save, cancel] = useDelayedSave(saveComment, 1500);

  const handleChange = (e) => {
    cancel();
    setCurrentComment((prev) => ({ ...prev, message: e }));
    save(e);
  };

  const handleSubmit = async (e) => {
    cancel();
    e.preventDefault();
    await saveComment(currentComment.message);
    publishComment({ commentId: currentComment.id, publish: true });
    setIsUpdating(false);
    setDraftComment(null);
    setActiveComment(null);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isNewComment) {
      deleteComment(currentComment.id);
    }
    setDraftComment(null);
    setIsUpdating(false);
    setActiveComment(null);
  };

  const actionHandler = (action, event) => {
    event.stopPropagation();
    switch (action) {
      case 'edit':
        setIsUpdating(true);
        break;
      case 'delete':
        handleDelete();
        break;
      case 'open':
      case 'close':
        updateCommentStatus({ commentId: comment.id, status: action !== 'open' });
        break;
      default:
        return;
    }
    setMenuOpen(false);
  };

  const renderCommentState = () => {
    if (comment.citationDeletedAt) {
      return `${i18n('comments.no-citation')} - `;
    }
    if (!isUniqueText) {
      return <span className="comment--warning">{i18n('comments.duplicate-citation')} </span>;
    }
  };

  return (
    <div
      className={`${isActiveComment ? 'active' : ''} ${!comment.publishedAt ? 'draft' : ''} ${comment.closedAt ? 'archived' : ''} comment`}
      onClick={() => {
        setActiveComment(comment);
        navigateTo(shadowHost, `anchor-${comment.onId}`);
        setShowResponses(true);
      }}
    >
      <span className="comment--citation">
        {renderCommentState()}
        {`"${formatCitation(comment.citation)}"`}
      </span>
      <CommentHeader
        isActiveComment={isActiveComment}
        comment={comment}
        isUpdating={isUpdating}
        setMenuOpen={setMenuOpen}
        menuOpen={menuOpen}
        actionHandler={actionHandler}
      />
      {isNewComment || isUpdating ? (
        <>
          <MentionInput value={currentComment.message} onChange={handleChange} ref={inputRef} placeHolder={i18n('comments.message.placeholder')} />

          <div className="comment--buttons">
            <button onClick={handleCancel} className="btn btn-ghost btn-md">
              {i18n('common.button.cancel')}
            </button>
            <button
              onClick={async (e) => {
                cancel();
                await saveComment(currentComment.message);
                publishComment({ commentId: currentComment.id, publish: false });
                setDraftComment(null);
                setIsUpdating(false);
                refetchComments();
              }}
              className="btn btn-secondary btn-md "
            >
              {i18n('common.save-draft')}
            </button>
            <button onClick={handleSubmit} className="btn btn-primary btn-md">
              {i18n('common.publish')}
            </button>
          </div>
        </>
      ) : (
        <div className="comment--message">{formatMessage(comment.message)}</div>
      )}
      {responses?.length > 0 && (
        <button
          className="not-a-button comment--response-button"
          onClick={(e) => {
            e.stopPropagation();
            setShowResponses(!showResponses);
          }}
        >
          {comment?.responses.length} {i18n('comments.response')}
        </button>
      )}
      {showResponses && responses && (
        <div className="comment--response--wrapper">
          {responses?.map((response) => {
            return (
              <Response
                key={response.id}
                response={response}
                isActiveResponse={activeResponse}
                setActiveResponse={setActiveResponse}
                sectionSuperId={sectionSuperId}
                setNewResponseComment={() => setResponseComment(generateResponseComment())}
              />
            );
          })}
        </div>
      )}
      {isActiveComment && !draftComment && !isUpdating && !isResponse && !comment.closedAt && comment.publishedAt && (
        <div className="comment--response--wrapper">
          <Response
            key={responseComment.id}
            response={responseComment}
            isActiveResponse={activeResponse}
            setActiveResponse={setActiveComment}
            sectionSuperId={sectionSuperId}
            setNewResponseComment={() => setResponseComment(generateResponseComment())}
          />
        </div>
      )}
    </div>
  );
};

const formatMessage = (text) => {
  const parts = text.split(/(@\w+)/g);
  return parts.map((part, index) =>
    part.startsWith('@') ? (
      <span key={index} className="comment--mention">
        {part}
      </span>
    ) : (
      part
    )
  );
};

const formatCitation = (text) => {
  if (text.length > 35) {
    return text.slice(0, 35) + '...';
  }
  return text;
};
