import React, { useCallback, useContext, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useSlate } from 'slate-react';
import moment from 'moment';
import request from 'app/api/request';
import { i18n } from 'app/utils/i18n';
import { NavbarPortal } from 'app/components';
import logo from 'app/images/Xplir_logo_icon.svg';
import { useEditorSelector } from 'app/state/contexts/EditorContext';
import { DiffContext } from 'app/state/contexts/DiffContext';
import { SidebarContext } from 'app/state/contexts/SidebarContext';
import { UserContext } from 'app/state/contexts/UserContext';
import { useSelector } from 'react-redux';

import { useLocation } from 'react-router';
import { FileZone } from 'app/components';
import { v4 as uuidv4 } from 'uuid';
import { LazyTippy, Modal } from 'app/components';
import ServerErrorHandler from 'app/ErrorHandler';
import DraftMark from './DraftMark';
import { useComments } from 'app/api/useComments';
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const getDocumentSaveSince = (lastSaved = null, isDirty) => {
  if (!lastSaved) {
    return '';
  }
  const now = moment();
  const d = moment(lastSaved);

  const diff = now.diff(d, 'minutes');

  if (diff === 0 && isDirty) {
    return 'Sparar ...';
  } else if (diff === 0) {
    const diffSec = now.diff(d, 'seconds');
    return `Sparat ${diffSec > 10 ? 'för ' + diffSec + ' sekunder sedan' : ''}`;
  } else if (diff < 60) {
    return `Sparades för ${diff} minuter sedan`;
  } else if (diff < 24 * 60) {
    return `Sparades för ${Math.round(diff / 60)} timmar sedan`;
  } else {
    return `Sparades för ${Math.round(diff / (24 * 60))} dagar sedan`;
  }
};

function versionNamedOrSaveStatus(selectedVersionName, createdAt, isDirty) {
  return selectedVersionName ? `${i18n('doc-editor.navbar.version')}: ${selectedVersionName}` : getDocumentSaveSince(createdAt, isDirty);
}

const SafeNavButton = ({ isDirty, prevLink, documentId }) => {
  const translationId = useQuery().get('translation');
  const history = useHistory();
  const location = useLocation();
  const handleGoBack = (e) => {
    e.preventDefault();
    if (isDirty) {
      window.alert(i18n('document.not-saved.warning-message'));
      if (translationId !== null) {
        history.push(`?translation=${translationId}`);
      } else {
        history.push(location.pathname);
      }
    } else {
      window.location.reload();
    }
  };

  if (isDirty) {
    return (
      <Link className="navigation" to="#" onClick={handleGoBack}>
        <button className="button--small clear">
          <i className="fa-regular fa-chevron-left" />
        </button>
      </Link>
    );
  }
  return (
    <Link className="navigation" to={prevLink || `/documents/${documentId}`}>
      <button className="button--small clear">
        <i className="fa-regular fa-chevron-left" />
      </button>
    </Link>
  );
};

const Navbar = React.memo(({ showXliff = true, prevLink = '', readOnly = false, isDraft = false }) => {
  const history = useHistory();
  const location = useLocation();
  const editor = useSlate();
  const { user, organizations, selectedOrganization } = useContext(UserContext);
  const { setIsSidebarOpen } = useContext(SidebarContext);
  const { documentId, sectionId } = useParams();
  const { diffState } = useContext(DiffContext);
  const [focusedDiff, setFocusedDiff] = useState(0);
  const { comments, isCommentsLoading } = useComments(sectionId);
  const [selectedTranslation] = useState(useQuery().get('translation'));
  const org = organizations.find((o) => o.id === selectedOrganization);
  const reduxDocumentPermissions = useSelector((state) => state.document.documentPermissions);
  const isDirty = useEditorSelector((editor) => editor.isDirty);
  const reduxSectionPermissions = useSelector((state) => state.document.sectionPermissions);
  const {
    superId: documentSuperId,
    revisionId: documentRevisionId,
    name: documentName,
    createdAt: documentCreatedAt,
    content: { locale: documentLocale },
  } = useEditorSelector((editor) => editor.pDocument);

  const currentSection = useEditorSelector((editor) => editor.pDocument?.content?.sections?.find((section) => section.superId === sectionId));
  const selectedVersionName = useSelector((state) => state.document.versions.find((version) => version.documentRevisionId === documentRevisionId)?.name);
  const targetLanguages = currentSection?.targetLanguages;
  const [versionName, setVersionName] = useState('');
  const [errorName, setErrorName] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const mentionedComments =
    comments?.filter((comment) => comment?.message?.includes(`@${user.firstName}${user.lastName}`) && !comment.closedAt && comment.publishedAt) ?? [];

  const handleSubmitVersion = (event) => {
    event.preventDefault();
    if (!validName(versionName)) {
      setErrorName(true);
      return;
    }

    request
      .post(`/gaby/versions/${documentId}`, {
        documentSuperId,
        documentRevisionId,
        name: versionName,
      })
      .then(({ data: version }) => {
        history.push(`?revision=${version.documentRevisionId}`);
        setModalOpen(false);
        setVersionName('');
      })
      .catch(ServerErrorHandler);
  };

  function validName(name) {
    return name.trim().length > 0 && name.trim().length < 65;
  }

  const handleVersionNameUpdate = (event) => {
    setVersionName(event.target.value);
  };
  const xliff = useCallback(
    (e) => {
      request
        .get(`/gaby/sections/xliff/${currentSection.revisionId}${selectedTranslation ? '?target=' + selectedTranslation : ''}`)
        .then((res) => res.data)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data.xliff], { type: 'text/xml' }));

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `translation${'_' + currentSection.name + '_(' + moment(new Date()).format('YYYYMMDD_HHmm') + ')'}.xliff`);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        });
    },
    [currentSection, selectedTranslation]
  );

  const handleStepChange = (step) => () => {
    setFocusedDiff((focusedDiff + step) % Object.keys(diffState.right).length);
  };

  const handleLangChange = (event) => {
    if (event.target.value.toString().trim() !== '') {
      history.push(`?translation=${event.target.value.toString()}`);
    } else {
      history.push(location.pathname);
    }
    window.location.reload();
  };

  const handleCloseModal = () => {
    setVersionName('');
    setErrorName(false);
    setModalOpen(false);
  };

  const onUploadSuccess = ({ data }) => {
    request
      .get(`/gaby/sections/xliff/${currentSection.revisionId}/${data.revisionId}`)
      .then((res) => res.data)
      .then((translatedSection) => {
        const revisionId = uuidv4();
        request
          .post('/gaby/sections', {
            superId: uuidv4(),
            revisionId,
            content: translatedSection.content,
            documentSuperId: documentId,
          })
          .then(({ data: section }) => {
            request.post(`/gaby/documents/${documentId}/actions`, {
              action: 'create_section_target_lang',
              revisionId: currentSection.revisionId,
              data: {
                superId: currentSection.superId,
                targetLanguage: {
                  locale: (currentSection?.locale ?? documentLocale) === 'sv-SE' ? 'en-US' : 'sv-SE',
                  name: data.filename + ' (' + moment(new Date()).format('YYYY-MM-DD HH:mm') + ')',
                  superId: section.superId,
                  revisionId: section.revisionId,
                },
              },
            });
          });
      });
  };

  return (
    <div>
      <NavbarPortal fullWidth>
        <div className="navbar--editor">
          <div className="logo">
            <img alt="logo" src={logo} />
          </div>
          <SafeNavButton isDirty={isDirty} documentId={documentId} prevLink={prevLink} />
          <div className="project">
            <Link className="me-3" to={`/documents/${documentId}`}>
              <p className="sub-heading mb-1">{org?.name || ''}</p>
              <div className="d-flex align-items-center">
                <h4>{documentName}</h4>
                <span className="ms-3 text-gray-900 fs-7">
                  {versionNamedOrSaveStatus(selectedVersionName, documentCreatedAt, isDirty)}
                  {isDirty && '*'}
                </span>
              </div>
            </Link>
          </div>
          <div className="controls">
            {diffState && (
              <>
                <button className="button--small clear" onClick={handleStepChange(1)}>
                  <i className="fa-regular fa-arrow-down-long" />
                </button>
                <button className="button--small clear" onClick={handleStepChange(-1)}>
                  <i className="fa-regular fa-arrow-up-long" />
                </button>
              </>
            )}
            {!readOnly && (
              <>
                <button
                  className="button--small clear"
                  onClick={() => {
                    editor.undo();
                  }}
                >
                  <i className="fa-regular fa-arrow-rotate-left" />
                </button>
                <button
                  className="button--small clear"
                  onClick={() => {
                    editor.redo();
                  }}
                >
                  <i className="fa-regular fa-arrow-rotate-right" />
                </button>
              </>
            )}
            {isDraft ? (
              <DraftMark />
            ) : (
              <>
                <div className="version-icon" onClick={() => setIsSidebarOpen(4)}>
                  {!isCommentsLoading
                    ? comments.filter((comment) => comment.publishedAt !== null && comment.onType !== 'comment' && comment.closedAt === null).length
                    : 0}
                  <i className="fa-regular fa-message nav-btn-icon"></i>
                </div>

                <Link to={`/sbs/documents/${documentId}/sections/${sectionId}`} className="navbar-link-group" target="_blank" rel="noopener noreferrer">
                  <div className="button grey200">{i18n('doc-editor.navbar.compare-versions')}</div>
                </Link>
                {reduxDocumentPermissions.includes('ADMIN') || Object.values(reduxSectionPermissions).some((p) => p === 'WRITE ') ? (
                  <div className="button grey200" onClick={() => setModalOpen(true)}>
                    {i18n('doc-editor.navbar.new-version')}
                    <i className="fa-regular fa-floppy-disk nav-btn-icon" />
                  </div>
                ) : null}
                {showXliff && (
                  <LazyTippy
                    interactive={!isDirty}
                    trigger={'click'}
                    content={
                      <div className="card support-modal">
                        <div className="button grey200" onClick={(e) => xliff(e)}>
                          {i18n('doc-editor.navbar.xliff')}
                          <i className="fa-regular fa-floppy-disk nav-btn-icon" />
                        </div>
                        {targetLanguages ? (
                          <select onChange={handleLangChange} value={selectedTranslation ? selectedTranslation : ''}>
                            {targetLanguages.map((_translation) => {
                              return (
                                <option key={_translation.superId} value={_translation.superId}>
                                  {_translation.name + ' (' + _translation.locale + ')'}
                                </option>
                              );
                            })}
                            <option key="org" value="">
                              Orginalspråk
                            </option>
                          </select>
                        ) : (
                          ''
                        )}
                        <FileZone type="xliff" documentId={documentId} onSuccess={onUploadSuccess} />
                      </div>
                    }
                  >
                    <div className="button grey200">{i18n('doc-editor.navbar.translation')}</div>
                  </LazyTippy>
                )}
              </>
            )}
            <div className="navbar-user-icon-wrapper">
              <div className="user--circle">
                {user.firstName[0].toUpperCase()}
                {user.lastName[0].toUpperCase()}
              </div>
            </div>
            <div className="navbar-notification-icon-wrapper">
              <i className="fa-regular fa-bell"></i>
              {mentionedComments.length > 0 && <p className="navbar-notification-count-icon">{mentionedComments.length}</p>}
            </div>
          </div>
        </div>
      </NavbarPortal>
      <Modal show={modalOpen}>
        <div className="header d-flex">
          <h3>{i18n('project-details.new-version.modal.title')}</h3>
          <div className="close" onClick={handleCloseModal}>
            <i className="fa-regular fa-xmark" />
          </div>
        </div>
        <div className="body">
          <form onSubmit={handleSubmitVersion} className={errorName ? 'form with-footer error' : 'form with-footer'}>
            <div className="mt-4">
              <label>
                <input type="text" className="form-control" ref={(o) => o?.focus()} placeholder="" value={versionName} onChange={handleVersionNameUpdate} />
              </label>
              <span className="text-help">{errorName ? i18n('overview.new-version.modal.field.nameError') : ''}</span>
            </div>
            <div className="d-flex justify-content-end">
              <button type="button" className="button clear" onClick={handleCloseModal}>
                {i18n('common.button.cancel')}
              </button>
              <button type="submit" className="button primary">
                {i18n('common.button.save')}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
});

export default Navbar;
