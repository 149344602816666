import { useQuery } from 'react-query';
import request from 'app/api/request';
import { errorHandler } from 'app/utils/messages';

const queryKeys = {
  currentUser: 'currentUser',
  currentPermissions: 'currentPermissions',
  organizationId: 'organizationId',
};

export const useCurrentUser = () => {
  return useQuery(
    [queryKeys.currentUser],
    async () => {
      const responseUser = await request.get(`/registry/users/current-user`);
      const responsePermissions = await request.get(`/registry/users/current-user-permissions`);
      return { user: responseUser.data, permissions: responsePermissions.data };
    },
    {
      onError: errorHandler,
    }
  );
};

export const useCurrentUserPermissions = () =>
  useQuery(
    [queryKeys.currentUser],
    async () => {
      const response = await request.get(`/registry/users/current-user-permissions`);
      return response.data;
    },
    {
      onError: errorHandler,
    }
  );

export const useUsersInOrganization = (organizationId) => {
  return useQuery(
    [queryKeys.organizationId, organizationId],
    async () => {
      const response = await request.get(`/registry/users?attributeId=${organizationId}&size=1000`);
      return response.data._embedded?.users ?? [];
    },
    { onError: errorHandler, keepPreviousData: true }
  );
};

export const useUsersWithSectionPermission = (documentId, sectionSuperId) => {
  return useQuery(
    [queryKeys.currentPermissions],
    async () => {
      const { data: documentPermissions } = await request.get(`/gaby/documents/${documentId}/permissions`);
      const { data: users } = await request.get(`/registry/users?size=1000`);
      const usersWithAcces = users._embedded.users.filter((user) => {
        const permission = documentPermissions.userSectionPermissions.find(
          (perm) => perm.sectionSuperId === sectionSuperId && perm.userId === user.id
        )?.permission;
        return permission === 'COMMENT' || permission === 'WRITE';
      });

      return usersWithAcces ?? [];
    },
    { onError: errorHandler }
  );
};
