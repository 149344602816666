import { Element, Transforms, Editor } from 'slate';
import { isSimpleBlockActive } from 'app/slate/functions';
import { useRef, useState, useEffect, useCallback, useContext } from 'react';
import { refHandler } from 'app/utils/helper-functions';
import { useDelayedSave } from 'app/utils/hooks/delayed-save';
import { useEditorSelector } from 'app/state/contexts/EditorContext';
import { useSaveStyle, useStyles } from 'app/api/style-queries';
import { UserContext } from 'app/state/contexts';
import { useSlate, ReactEditor } from 'slate-react';
import { SidebarContext } from 'app/state/contexts/SidebarContext';

const isStyledParagraph = (node) => Element.isElement(node) && node.type === 'styled-paragraph';

const withStyledParagraph =
  (options = {}) =>
  (editor) => {
    const { renderElement, isBlock, normalizeNode } = editor;
    editor.isBlock = (element) => isStyledParagraph(element) || isBlock(element);

    editor.renderElement = (props) => {
      if (isStyledParagraph(props.element)) {
        return <StyledParagraphElement {...props} />;
      } else {
        return renderElement(props);
      }
    };

    editor.normalizeNode = ([node, path]) => {
      normalizeNode([node, path]);
    };

    return editor;
  };

export const StyledParagraphElement = ({ attributes, children, element }) => {
  const ref = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const startMouseRef = useRef({ x: 0, y: 0 });
  const startPosRef = useRef({ x: 0, y: 0 });
  const { pDocument } = useEditorSelector((editor) => editor);
  const activeSection = useEditorSelector((editor) => editor.activeSection);
  const editor = useSlate();
  const pageStyleId = pDocument.content.design.find((d) => d.pageId === activeSection.uuid)?.styleId;
  const { selectedOrganization } = useContext(UserContext);
  const { setStyledParagraph } = useContext(SidebarContext);
  const { data: stylesData = [] } = useStyles({
    organization: selectedOrganization,
    documentSuperId: pDocument.superId,
    styleIds: [pageStyleId],
  });
  const [pageStyle] = stylesData;
  const nodeStyle = pageStyle?.content?.[`xrp-super-id-${element?.id}`];

  const [position, setPosition] = useState({
    x: nodeStyle?.['left']?.value ?? 0,
    y: nodeStyle?.['top']?.value ?? 0,
  });
  const [positionInitialized, setPositionInitialized] = useState(false);

  useEffect(() => {
    if (!positionInitialized && nodeStyle) {
      setPosition({
        x: nodeStyle['left']?.value ?? 0,
        y: nodeStyle['top']?.value ?? 0,
      });
      setPositionInitialized(true);
    }
  }, [nodeStyle, positionInitialized]);

  const { mutate: saveStyle } = useSaveStyle({
    organization: selectedOrganization,
    documentSuperId: pDocument.superId,
    pageId: activeSection.uuid,
    templateSuperId: activeSection.templateSuperId,
  });

  const isDraggable = !!element.connected;

  const handleMouseDown = (e) => {
    const selectedNode = isInsideStyledParagraph(editor);
    if (!selectedNode || selectedNode.id !== element.id) {
      const path = ReactEditor.findPath(editor, element);
      Transforms.select(editor, path);

      return;
    }
    if (!isDraggable || !ref.current) return;
    e.preventDefault();
    startMouseRef.current = { x: e.clientX, y: e.clientY };
    startPosRef.current = { x: position.x, y: position.y };
    setIsDragging(true);
  };

  const savePosition = () => {
    const classStyle = {
      [`xrp-super-id-${element?.id}`]: {
        ...element.currentStyle,
        top: {
          value: position.y,
          unit: 'px',
        },
        left: {
          value: position.x,
          unit: 'px',
        },
      },
    };
    saveStyle(classStyle);
  };
  const [save] = useDelayedSave(savePosition, 1500);

  const handleMouseMove = useCallback(
    (e) => {
      if (!isDragging) return;
      const deltaX = e.clientX - startMouseRef.current.x;
      const deltaY = e.clientY - startMouseRef.current.y;
      let newX = startPosRef.current.x + deltaX;
      let newY = startPosRef.current.y + deltaY;

      if (ref.current) {
        const pageContent = ref.current.closest('.page-content');
        if (pageContent) {
          const containerRect = pageContent.getBoundingClientRect();
          const elementRect = ref.current.getBoundingClientRect();
          const minX = 0;
          const minY = 0;
          const maxX = containerRect.width - elementRect.width;
          const maxY = containerRect.height - elementRect.height;
          newX = Math.max(minX, Math.min(newX, maxX));
          newY = Math.max(minY, Math.min(newY, maxY));
        }
      }

      setPosition({ x: newX, y: newY });
    },
    [isDragging]
  );

  const handleMouseUp = useCallback(() => {
    if (isDragging) {
      setIsDragging(false);
    }
    save();
  }, [isDragging, save]);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.setProperty('top', `${position.y}px`, 'important');
      ref.current.style.setProperty('left', `${position.x}px`, 'important');
    }
  }, [position]);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  return (
    <div
      {...attributes}
      ref={refHandler(attributes, ref)}
      style={{
        willChange: 'transform',
        cursor: isDraggable ? (isDragging ? 'grabbing' : 'grab') : 'text',
      }}
      contentEditable={!isDraggable}
      suppressContentEditableWarning={true}
      onMouseDown={isDraggable ? handleMouseDown : undefined}
      className={`xrp-super-id-${element.id}`}
      onClick={() => {
        setStyledParagraph(element);
      }}
    >
      {children}
    </div>
  );
};

export const insertStyledParagraph = (editor, uuid) => {
  const isActive = isSimpleBlockActive(editor, 'styled-paragraph');

  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'styled-paragraph',
    split: true,
  });

  if (!editor.selection) return;

  Transforms.setNodes(
    editor,
    { type: 'paragraph' },
    {
      at: editor.lastSelection,
    }
  );

  if (!isActive) {
    const block = { type: 'styled-paragraph', children: [], id: uuid };
    Transforms.wrapNodes(editor, block);
  }
};

export const unWrapStyledParagraph = (editor, id) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === 'styled-paragraph' && n.id === id,
  });

  if (!match) return;

  const [, path] = match;
  Transforms.unwrapNodes(editor, {
    at: path,
    match: (n) => n.type === 'styled-paragraph',
  });
};

export const isInsideStyledParagraph = (editor) => {
  if (!editor.selection) return null;
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === 'styled-paragraph',
  });
  return match ? match[0] : null;
};

export const toggleConnect = (editor, id, currentStyle) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === 'styled-paragraph' && n.id === id,
  });

  if (match) {
    const [, path] = match;
    const [node] = Editor.node(editor, path);
    const connected = node.connected || false;

    if (connected) {
      Transforms.setNodes(editor, { connected: false, currentStyle }, { at: path });
    } else {
      Transforms.setNodes(editor, { connected: true, currentStyle }, { at: path });
    }
  }
};

export default withStyledParagraph;
