import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { IconButton, Modal, DraggableTable } from 'app/components';
import request from 'app/api/request';
import { emptyPage } from 'app/slate/extensions/page';

import { UserContext } from 'app/state/contexts/UserContext';
import { usePermissions } from 'app/utils/hooks/document-permission';
import ServerErrorHandler from 'app/ErrorHandler';
import { i18n } from 'app/utils/i18n';
import CloneDocumentModal from './CloneDocumentModal';
import { queryKeys, useExportDocument, useGetDocument, useGetDocumentVersions, useSectionReorder } from 'app/api/documents/document-queries';
import authStore from 'app/utils/authStore';
import { useQueryClient } from 'react-query';
import { formatDate } from 'app/utils/date';
import { Spinner } from 'app/components';
import { dispatchMessage, EventType } from 'app/useToasts';
import { SelectLanguageModal } from '../../components/modal/LanguageModal';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function validName(name) {
  return name.trim().length > 0 && name.trim().length < 65;
}

const Overview = () => {
  const history = useHistory();
  let { url } = useRouteMatch();
  const { documentId } = useParams();
  const query = useQuery();
  const documentRevisionId = query.get('revision');
  const { selectedOrganization, organizations } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { mutate: exportDocument, isLoading: isExportingDocument } = useExportDocument();
  const [sectionName, setSectionName] = useState('');
  const [versionName, setVersionName] = useState('');
  const [sectionToUpdate, setSectionToUpdate] = useState();
  const [sectionToDelete, setSectionToDelete] = useState(null);
  const [modalDeleteConfirmOpen, setModalDeleteConfirmOpen] = useState(false);
  const [versionSelectedForClone, setVersionSelectedForClone] = useState(null);
  const [errorName, setErrorName] = useState(false);

  const [modalSectionOpen, setModalSectionOpen] = useState(false);
  const [modalVersionOpen, setModalVersionOpen] = useState(false);
  const [modalNewNameOpen, setModalNewNameOpen] = useState(false);
  const [modalNewLanguageOpen, setModalNewLanguageOpen] = useState(false);
  const [modalPdfLanguageOpen, setModalPdfLanguageOpen] = useState(false);
  const [modalCloneOpen, setModalCloneOpen] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const { documentPermissions, sectionPermissions, fetchPermissionData } = usePermissions(documentId);

  const revisionDocument = useGetDocument(documentId, selectedOrganization, documentRevisionId);
  const draftDocument = useGetDocument(documentId, selectedOrganization);
  const versions = useGetDocumentVersions(documentId, documentRevisionId, draftDocument.data);
  const sectionReorder = useSectionReorder();
  const document = revisionDocument.data || draftDocument.data;
  const companyName = organizations.find((organization) => selectedOrganization === organization.id).name;
  const documentData = { ...document, companyName: companyName };

  const documentIsLoading = revisionDocument.isLoading || draftDocument.isLoading || versions.isLoading;
  const downloadPdf = ({ documentSuperId, documentRevisionId }, documentData, locale) => {
    setLoadingExport(true);
    const url = `/gaby/pdf?documentSuperId=${documentSuperId}&documentRevisionId=${documentRevisionId}${locale ? '&locale=' + locale : ''}`;
    request
      .get(url)
      .then((res) => res.data)
      .then(({ data }) => {
        const element = window.document.createElement('a');
        element.setAttribute('href', `data:application/pdf;base64,${encodeURIComponent(data)}`);
        element.setAttribute('download', `${documentData.companyName} - ${documentData.name}.pdf`);
        element.style.display = 'none';
        window.document.body.appendChild(element);
        element.click();
        window.document.body.removeChild(element);
        setLoadingExport(false);
      })
      .finally(() => setLoadingExport(false));
  };

  const downloadXhtml = ({ documentSuperId, documentRevisionId }, documentData, locale) => {
    setLoadingExport(true);
    const url = `/gaby/xhtml?documentSuperId=${documentSuperId}&documentRevisionId=${documentRevisionId}${locale ? '&locale=' + locale : ''}`;
    request
      .get(url)
      .then((res) => res.data)
      .then(({ data, validationErrors }) => {
        if (validationErrors > 0) {
          dispatchMessage(EventType.ADD_MESSAGE, {
            type: 'warning',
            title: i18n('server.error.validation.title'),
            text: `${i18n('server.error.validation.start')} ${validationErrors} ${i18n('server.error.validation.end')}`,
          });
        }
        const element = window.document.createElement('a');
        element.setAttribute('href', `data:application/xhtml+xml;base64,${encodeURIComponent(data)}`);
        element.setAttribute('download', `${documentData.companyName} - ${documentData.name}.xhtml`);
        element.style.display = 'none';
        window.document.body.appendChild(element);
        element.click();
        window.document.body.removeChild(element);
      })
      .catch(ServerErrorHandler)
      .finally(() => setLoadingExport(false));
  };

  useEffect(() => {
    if (versionSelectedForClone) {
      setModalCloneOpen(true);
    }
  }, [versionSelectedForClone]);

  const versionMenuItems = useCallback(
    (version) => {
      const menu = [
        {
          title: i18n('project-details.named-version.download-pdf'),
          icon: 'file-pdf',
          onClick: () => downloadPdf(version, documentData),
        },
        {
          title: i18n('project-details.named-version.download-pdf-alt'),
          icon: 'file-pdf',
          onClick: () => {
            setSelectedVersion(version);
            setModalPdfLanguageOpen(true);
          },
        },
        {
          title: i18n('project-details.named-version.download-xhtml'),
          icon: 'file-code',
          onClick: () => downloadXhtml(version, documentData),
        },
      ];
      if (authStore.hasPermission('DOCUMENT_CLONE')) {
        menu.push({
          title: i18n('project-details.named-version.clone'),
          icon: 'clone',
          onClick: () => setVersionSelectedForClone(version),
        });
      }
      if (authStore.hasPermission('DOCUMENT_EXPORT')) {
        menu.push({
          title: i18n('project-details.named-version.export-json'),
          icon: isExportingDocument ? 'circle-notch fa-spin' : 'file-export',

          onClick: () => {
            if (!isExportingDocument) {
              exportDocument({ documentSuperId: version.documentSuperId, documentRevisionId: version.documentRevisionId, selectedOrganization });
            }
          },
        });
      }
      return menu;
    },
    // eslint-disable-next-line
    [selectedOrganization, isExportingDocument]
  );

  const sectionMenuItems = useCallback(
    (section) => [
      {
        title: i18n('project-details.section.edit-name'),
        icon: 'pen',
        onClick: () => {
          setSectionToUpdate(section);
          setSectionName(section.name);
          setModalNewNameOpen(true);
        },
      },
      {
        title: i18n('project-details.section.view-draft'),
        icon: 'eye',
        onClick: () => {
          history.push(
            `${url}/sections/${section.superId}?useDraft=true${
              documentRevisionId ? `&revision=${documentRevisionId}&sectionRevision=${section.revisionId}` : ''
            }`
          );
        },
      },
      {
        title: i18n('project-details.section.edit-lang'),
        icon: 'globe',
        onClick: () => {
          setSectionToUpdate(section);
          setModalNewLanguageOpen(true);
        },
      },
      {
        title: i18n('project-details.section.delete-section'),
        icon: 'trash',
        onClick: () => {
          setSectionToDelete(section);
          setModalDeleteConfirmOpen(true);
        },
      },
    ],
    [history, documentRevisionId, url]
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validName(sectionName)) {
      setErrorName(true);
      return;
    }
    const revisionId = uuidv4();
    request
      .post('/gaby/sections', {
        superId: uuidv4(),
        revisionId,
        content: [emptyPage(revisionId)],
        documentSuperId: documentId,
      })
      .then(({ data: section }) =>
        request.post(`/gaby/documents/${documentId}/actions?compileSass=true`, {
          action: 'create_section',
          data: {
            revisionId: section.revisionId,
            superId: section.superId,
            name: sectionName,
            locale: document.content.locale,
          },
        })
      )
      .then(() => {
        fetchPermissionData();
        setModalSectionOpen(false);
        setSectionName('');
        setErrorName(false);
        queryClient.invalidateQueries(queryKeys.document);
      });
  };

  const handleNameChangeSubmit = (event) => {
    event.preventDefault();
    if (!validName(sectionName)) {
      setErrorName(true);
      return;
    }

    request
      .post(`/gaby/documents/${documentId}/actions`, {
        action: 'update_section_name',
        revisionId: sectionToUpdate.revisionId,
        data: {
          superId: sectionToUpdate.superId,
          name: sectionName,
        },
      })
      .then(() => {
        setModalNewNameOpen(false);
        setSectionName('');
        setErrorName(false);
        queryClient.invalidateQueries(queryKeys.document);
      });
  };

  const handleDeleteSection = useCallback(() => {
    if (!sectionToDelete) return;
    request
      .post(`/gaby/documents/${documentId}/actions`, {
        action: 'delete_section',
        revisionId: sectionToDelete.revisionId,
        data: {
          superId: sectionToDelete.superId,
        },
      })
      .then(() => {
        setModalDeleteConfirmOpen(false);
        setSectionToDelete(null);
        queryClient.invalidateQueries(queryKeys.document);
      })
      .catch(ServerErrorHandler);
  }, [sectionToDelete, documentId, queryClient]);

  const handleSubmitVersion = (event) => {
    event.preventDefault();
    if (!validName(versionName)) {
      setErrorName(true);
      return;
    }

    request
      .post(`/gaby/versions/${documentId}`, {
        documentSuperId: draftDocument.data.superId,
        documentRevisionId: draftDocument.data.revisionId,
        name: versionName,
      })
      .then(() => {
        queryClient.invalidateQueries(queryKeys.versions);
        setModalVersionOpen(false);
        setVersionName('');
      })
      .catch(ServerErrorHandler);
  };

  const handleSubmitLang = (event) => {
    request
      .post(`/gaby/documents/${documentId}/actions`, {
        action: 'update_section_locale',
        revisionId: sectionToUpdate.revisionId,
        data: {
          superId: sectionToUpdate.superId,
          locale: event,
        },
      })
      .then(() => {
        setModalNewLanguageOpen(false);
      })
      .catch(ServerErrorHandler);
  };

  const handleSubmitPdfLang = (event) => {
    if (!selectedVersion) return;
    setModalPdfLanguageOpen(false);
    downloadPdf(selectedVersion, documentData, event);
  };

  const handleVersionNameUpdate = (event) => {
    setVersionName(event.target.value);
  };

  const handleSelectVersion = (version) => {
    if (version.draft) {
      history.push(`?`);
    } else {
      history.push(`?revision=${version.documentRevisionId}`);
    }
  };

  const handleSectionNameUpdate = (event) => {
    setSectionName(event.target.value);
  };

  if (documentIsLoading) {
    return <div>{i18n('common.loading-message')}</div>;
  }

  const altLocale = document.content.locale === 'sv-SE' ? 'en-US' : 'sv-SE';

  const closeCloneModal = () => {
    setModalCloneOpen(false);
    setVersionSelectedForClone(null);
  };

  if (versions.data[0]?.documentRevisionId) {
    console.log('PDF', window.location.origin + `/client/documents/${documentId}/pdf/${versions.data[0]?.documentRevisionId}`);
  }

  const handleCloseModal = () => {
    setSectionName('');
    setVersionName('');
    setErrorName(false);
    setModalNewNameOpen(false);
    setModalSectionOpen(false);
    setModalVersionOpen(false);
    setModalNewLanguageOpen(false);
    setModalPdfLanguageOpen(false);
  };

  const onReorderSections = (reorderedList) => {
    sectionReorder.mutate({
      documentId,
      sectionOrder: reorderedList.map((s) => s.id),
    });
  };

  const sectionItems = document.content.sections.map((section) => ({
    id: section.superId,
    title: section.name,
    disabled: !sectionPermissions[section.superId],
    dropdownItems: sectionMenuItems(section),
    linkTo: `${url}/sections/${section.superId}${documentRevisionId ? `?revision=${documentRevisionId}&sectionRevision=${section.revisionId}` : ''}`,
  }));
  const versionItems =
    versions.data?.map((version) => ({
      title: version.name,
      detail: formatDate(version.createdAt),
      dropdownItems: versionMenuItems(version, altLocale),
      onClick: () => handleSelectVersion(version),
      selected: version.selected,
    })) || [];

  return (
    <div className="project--overview">
      {loadingExport && (
        <div className="project--loading--container">
          <div className="project--loading--modal">
            <Spinner />
            <p>{i18n('project-details.download-document')}</p>
          </div>
        </div>
      )}
      <div className="columns two mb-2">
        <h2>{i18n('project-details.sections-list.title')}</h2>
        <div className="action--align--left">
          {draftDocument?.data?.name === document?.name && documentPermissions.includes('ADMIN') && (
            <IconButton icon="plus" onClick={() => setModalSectionOpen(true)} />
          )}
        </div>
      </div>
      {!!document.content.sections.length ? null : <h4>{i18n('project-details.no-sections-message')}</h4>}
      <DraggableTable items={sectionItems} onSort={onReorderSections} />
      <div className="columns two mb-2 mt-5">
        <h2>{i18n('project-details.title.named-versions')}</h2>
        <div className="action--align--left">
          {(documentPermissions.includes('ADMIN') || Object.values(sectionPermissions).some((p) => p === 'WRITE')) && (
            <IconButton icon="plus" onClick={() => setModalVersionOpen(true)} />
          )}
        </div>
      </div>
      <DraggableTable items={versionItems} />

      <Modal show={modalSectionOpen}>
        <div className="header d-flex">
          <h3>{i18n('project-details.new-section.modal.title')}</h3>
          <div className="close" onClick={handleCloseModal}>
            <i className="fa-regular fa-xmark" />
          </div>
        </div>
        <form onSubmit={handleSubmit} className={errorName ? 'form with-footer error' : 'form with-footer'}>
          <div className="mt-4">
            <label>
              <input className="form-control" type="text" ref={(o) => o?.focus()} value={sectionName} placeholder="" onChange={handleSectionNameUpdate} />
            </label>
            <span className="text-help">{errorName ? i18n('dashboard.new-project.modal.field.nameError') : ''}</span>
          </div>
          <div className="d-flex justify-content-end">
            <button type="button" className="button clear" onClick={handleCloseModal}>
              {i18n('common.button.cancel')}
            </button>
            <button type="submit" className="button primary">
              {i18n('common.button.save')}
            </button>
          </div>
        </form>
      </Modal>

      <Modal show={modalNewNameOpen}>
        <div className="header d-flex">
          <h3>{i18n('project-details.new-name.modal.title')}</h3>
          <div className="close" onClick={handleCloseModal}>
            <i className="fa-regular fa-xmark" />
          </div>
        </div>
        <div className="body ">
          <form onSubmit={handleNameChangeSubmit} className={errorName ? 'form with-footer error' : 'form with-footer'}>
            <div className="mt-4">
              <label>
                <input type="text" className="form-control" ref={(o) => o?.focus()} value={sectionName} placeholder="" onChange={handleSectionNameUpdate} />
              </label>
              <span className="text-help">{errorName ? i18n('dashboard.new-project.modal.field.nameError') : ''}</span>
            </div>
            <div className="d-flex justify-content-end">
              <button type="button" className="button clear" onClick={handleCloseModal}>
                {i18n('common.button.cancel')}
              </button>
              <button type="submit" className="button primary">
                {i18n('common.button.save')}
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal show={modalVersionOpen}>
        <div className="header d-flex">
          <h3>{i18n('project-details.new-version.modal.title')}</h3>
          <div className="close" onClick={handleCloseModal}>
            <i className="fa-regular fa-xmark" />
          </div>
        </div>
        <div className="body">
          <form onSubmit={handleSubmitVersion} className={errorName ? 'form with-footer error' : 'form with-footer'}>
            <div className="mt-4">
              <label>
                <input type="text" className="form-control" ref={(o) => o?.focus()} placeholder="" value={versionName} onChange={handleVersionNameUpdate} />
              </label>
              <span className="text-help">{errorName ? i18n('overview.new-version.modal.field.nameError') : ''}</span>
            </div>
            <div className="d-flex justify-content-end">
              <button type="button" className="button clear" onClick={handleCloseModal}>
                {i18n('common.button.cancel')}
              </button>
              <button type="submit" className="button primary">
                {i18n('common.button.save')}
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal show={modalDeleteConfirmOpen} onClose={() => setModalDeleteConfirmOpen(false)}>
        <div className="modal-body">{i18n('project-details.section.delete-section-text') + ` "${sectionToDelete?.name}"?`}</div>
        <div className="modal-footer">
          <button className="button clear" onClick={() => setModalDeleteConfirmOpen(false)}>
            {i18n('common.button.cancel')}
          </button>
          <button className="button danger" onClick={handleDeleteSection}>
            {i18n('project-details.section.delete-section')}
          </button>
        </div>
      </Modal>

      <SelectLanguageModal
        show={modalPdfLanguageOpen}
        onClose={handleCloseModal}
        title={i18n('project-details.new-lang.modal.title')}
        onSubmit={handleSubmitPdfLang}
        currentLocale={document.content.locale}
        confirmText={i18n('common.download')}
      />
      <SelectLanguageModal
        key={sectionToUpdate?.superId ?? 'default'}
        show={modalNewLanguageOpen}
        onClose={handleCloseModal}
        title={i18n('project-details.new-lang.modal.title')}
        onSubmit={handleSubmitLang}
        currentLocale={sectionToUpdate?.locale ?? document.content.locale}
        confirmText={i18n('common.confirm')}
      />
      <CloneDocumentModal
        show={modalCloneOpen}
        onClose={closeCloneModal}
        documentVersion={versionSelectedForClone}
        selectedOrganization={selectedOrganization}
      />
    </div>
  );
};

export default Overview;
