import { forwardRef, useState, useContext } from 'react';
import { useSlate } from 'slate-react';
import { Range, Editor } from 'slate';
import { ChromePicker } from 'react-color';
import { useStyle } from 'app/api/style-queries';
import { useEditorSelector } from 'app/state/contexts/EditorContext';
import { UserContext } from '../../../state/contexts/UserContext';

export const ColorButton = forwardRef(({ icon, css }, ref) => {
  const editor = useSlate();
  const { selection } = editor;
  const hasSelectedText = selection && !Range.isCollapsed(selection);

  return (
    <button ref={ref} className={`${css}`} disabled={!hasSelectedText}>
      <i className={`fa-regular fa-${icon}`} />
    </button>
  );
});

export const ColorPicker = forwardRef(({ handleChangeCallBack, type }, ref) => {
  const [color, setColor] = useState('#fff');
  const { pDocument } = useEditorSelector((editor) => editor);
  const styleId = pDocument.content.design.find((d) => d.type === 'graphical-variables')?.styleId;
  const { selectedOrganization } = useContext(UserContext);
  const { data, isLoading } = useStyle({
    organization: selectedOrganization,
    documentSuperId: pDocument.superId,
    styleId,
  });
  const editor = useSlate();
  const handleChangeComplete = (color) => {
    if (handleChangeCallBack && typeof handleChangeCallBack === 'function') {
      handleChangeCallBack(color);
      return;
    }
    if (color === '') {
      Editor.removeMark(editor, type);
    } else {
      Editor.addMark(editor, type, color.hex);
    }
  };
  return (
    <div className="color-picker-wrapper">
      <ChromePicker ref={ref} color={color} onChange={setColor} onChangeComplete={(color) => handleChangeComplete(color)} className="color-picker-inner" />
      <p style={{ margin: 0 }}>Temafärger: </p>
      {!isLoading && data && (
        <div className="theme-colors-wrapper">
          {Object.keys(COLOR_MAP).map((key) => {
            return (
              <div
                key={key}
                className="theme-color"
                style={{ backgroundColor: data.content[key] }}
                onClick={() => {
                  setColor(data.content[key]);
                  handleChangeComplete({ hex: data.content[key] });
                }}
              />
            );
          })}
          <div key="color-unset" className="theme-color theme-unset fa-2x fa-regular fa-ban" onClick={() => handleChangeComplete('')} />
        </div>
      )}
    </div>
  );
});

const COLOR_MAP = {
  xrpPrimaryColor: {
    label: 'graphical-variable.xrpPrimaryColor',
  },
  xrpSecondaryColor: {
    label: 'graphical-variable.xrpSecondaryColor',
  },
  xrpTertiaryColor: {
    label: 'graphical-variable.xrpTertiaryColor',
  },
  xrpQuaternaryColor: {
    label: 'graphical-variable.xrpQuaternaryColor',
  },
  xrpHeadingColor: {
    label: 'graphical-variable.xrpHeadingColor',
  },
};
