import { useContext } from 'react';
import { useSlate } from 'slate-react';
import { SidebarContext } from 'app/state/contexts/SidebarContext';
import { insertStyledParagraph } from 'app/slate/extensions/styled-paragraph';
import { v4 as uuidv4 } from 'uuid';
export const AddStyledParagraph = () => {
  const { setStyledParagraph, setIsSidebarOpen } = useContext(SidebarContext);

  const editor = useSlate();
  const handleClick = (e) => {
    e.preventDefault();
    const styledParagraphId = uuidv4();
    insertStyledParagraph(editor, styledParagraphId);
    setStyledParagraph({ id: styledParagraphId, connected: false });
    setIsSidebarOpen(6);
  };

  return (
    <div className="styled-paragraph--add-paragraph" style={{ marginBottom: '20px' }} onMouseDown={handleClick}>
      <i className="fa-regular fa-rectangle-list" />
    </div>
  );
};
