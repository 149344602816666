import React from 'react';
import DropdownList from 'react-widgets/DropdownList';

const Dropdown = ({ children, disabled, items = [], onChange, value, wrapper, sm, groupbyKey, placeHolder }) => {
  const selectedItem = items.find((item) => JSON.stringify(item.value) === JSON.stringify(value));
  const WrapperComponent = wrapper;

  const RenderItem = ({ item }) => {
    if (!item) {
      return null;
    }
    return (
      <>
        {WrapperComponent ? (
          <WrapperComponent item={item} />
        ) : (
          <div className="d-flex align-items-center">
            {item.icon ? <span className="me-2">{item.icon}</span> : null}
            <span>{item.title}</span>
          </div>
        )}
      </>
    );
  };

  const handleChange = (selected) => {
    onChange(selected.value);
  };

  return (
    <DropdownList
      disabled={disabled}
      containerClassName={sm ? 'react-widget-sm' : ''}
      onChange={handleChange}
      dataKey="value"
      data={items}
      value={selectedItem}
      defaultValue={value}
      renderValue={children ? () => children : RenderItem}
      renderListItem={RenderItem}
      groupBy={groupbyKey}
      placeholder={placeHolder}
    />
  );
};

const DropdownButton = ({ icon, title, disabled }) => {
  return (
    <>
      <div className="d-flex align-items-center">
        {icon ? <span className="me-2">{icon}</span> : null}
        <span>{title}</span>
      </div>
    </>
  );
};

Dropdown.DropdownButton = DropdownButton;

export default Dropdown;
