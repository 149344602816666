import React, { useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import FileZone, { FileZoneTemplate } from 'app/components/FileZone';
import moment from 'moment';
import { i18n } from 'app/utils/i18n';
import { DetailsTable } from './details-table';
import { useXlsxFile, useReferenceData, queryKeys, useXlsxFiles } from './reference-data-queries';

import { useQueryClient } from 'react-query';
import { useEditorSelector } from 'app/state/contexts/EditorContext';
import { calculatePageOffset } from 'app/slate/components/page/utils';
import { UpdateFinancialData, CurrentFileBlob } from './update-modal/UpdateReferenceData';
import { FilesListView } from './FilesListView';
import { Clickable } from 'app/components/Clickable';

export default function ReferenceDataDetails(props) {
  const { showDetails, setShowDetails } = props;
  const { documentId, refId } = useParams();
  const queryClient = useQueryClient();
  const xlsxFiles = useXlsxFiles(documentId);

  const modal = useRef(null);

  const referenceData = useReferenceData(refId, documentId);
  const xlsxFile = useXlsxFile(referenceData.data?.fileRevisionId);

  const handleClickForValueUpdate = () => {
    modal.current.open();
  };

  const handleUploadSuccess = () => {
    queryClient.invalidateQueries(queryKeys.xlsxFiles);
    setShowDetails(true);
  };

  const handleUpdateAbort = () => {
    modal.current.close();
  };

  const handleUpdateSuccess = () => {
    modal.current.close();
  };

  return (
    <div className="card-body">
      {referenceData.isLoading ? null : (
        <div>
          <div className="d-flex mb-2 align-items-center">
            <h3 className="mt-1 mb-0 two-line-overflow">{referenceData.data.name}</h3>
            <div className="ms-2 text-nowrap">
              <UsedTableIcon documentId={documentId} usage={referenceData.data.usage} />
            </div>
          </div>
          <p className="date-text">{moment(referenceData.data.createdAt).format('D MMM. YYYY, HH:mm')}</p>
          {referenceData.isLoading || !referenceData.data.selectionContent ? (
            <div className="row justify-content-center">
              <div className="col-4">
                <FileZone type="xlsx" documentId={documentId} onSuccess={handleUploadSuccess} url={'/gaby/reference-data/xlsx'} />
              </div>
              {!xlsxFiles.data?.length ? null : (
                <>
                  <div className="col-1 d-flex align-items-center justify-content-center">{i18n('reference-data.details.links.or')}</div>
                  <div className="col-4" onClick={() => modal.current.open()}>
                    <FileZoneTemplate title={i18n('reference-data.details.link.add-to-uploaded-document')} icon="fa-regular fa-link" />
                  </div>
                </>
              )}
            </div>
          ) : (
            <>
              {false ? null : (
                <CurrentFileBlob file={xlsxFile.data?.selectedXlsx}>
                  <Clickable
                    className="update-selection-values"
                    onClick={() => {
                      handleClickForValueUpdate();
                    }}
                  >
                    {i18n('reference-data.details.link.update-selection-values')}
                  </Clickable>
                </CurrentFileBlob>
              )}
              <DetailsTable />
            </>
          )}
        </div>
      )}
      {showDetails && <FilesListView onCloseClick={() => setShowDetails(false)} referenceData={referenceData} onUploadSuccess={handleUploadSuccess} />}
      <UpdateFinancialData modalRef={modal} onAbort={handleUpdateAbort} onSuccess={handleUpdateSuccess} />
    </div>
  );
}

const UsedTableIcon = ({ usage, documentId }) => {
  const reduxDocument = useEditorSelector((editor) => editor.pDocument);
  const offset = calculatePageOffset(reduxDocument?.content?.pageNumbering);

  const icon = (metadata, message) => (
    <Link to={`/documents/${documentId}/sections/${metadata.sectionSuperId}#${metadata.pageId}`} key={metadata.pageNumber} className="in-doc-message">
      {message}
    </Link>
  );

  const pageNumber = (usage) => usage.pageNumber + offset;
  const sortedUsage = usage?.slice().sort((a, b) => pageNumber(a) - pageNumber(b));

  return sortedUsage?.length ? (
    sortedUsage.map((u) => icon(u, i18n('reference-data.details.tag.used-in-document') + ' ' + (pageNumber(u) < 1 ? '-' : pageNumber(u))))
  ) : (
    <span className="in-doc-message">{i18n('reference-data.details.tag.missing-in-document')}</span>
  );
};
