export const Leaf = ({ attributes, children, leaf, showFormatting }) => {
  const { bold, code, italic, underline, subscript, superscript, overflow, backgroundColor, color, commentId, activeComment } = leaf;
  const {
    parent: { formatting },
  } = children.props;

  if (showFormatting) {
    let text = children.props.leaf.text.slice();
    text = text
      .replace(/ /g, '·') // Replace spaces with U+00B7
      .replace(/\t/g, '↑'); // Replace tabs with U+2191

    let updatedText = '';
    for (let i = 0; i < text.length; i++) {
      const char = text[i];
      if (char === '\n' || char === '\r') {
        updatedText += '↵' + char;
      } else {
        updatedText += char;
      }
    }

    // Each new child is a paragraph, so always insert paragraph in the end of each child
    text = updatedText + '¶';
    children.props.leaf.text = text;
  }

  let className = '';
  let customAttributes = { ...attributes };
  if (bold) {
    if (formatting?.format === 'paragraph' || formatting?.format === 'p') {
      className += 'xrp-body-bold';
    } else if (!formatting?.format) {
      // TODO: GABY-1538: Formatting is undefined in the leaf
      // formatting is undefined, fallback to body as default
      className += 'xrp-body-bold';
    } else {
      className += 'xrp-heading-bold';
    }
  }

  if (italic) {
    if (formatting?.format === 'paragraph' || formatting?.format === 'p') {
      className += 'xrp-body-italic';
    } else if (!formatting?.format) {
      // TODO: GABY-1538: Formatting is undefined in the leaf
      // formatting is undefined, fallback to body as default
      className += 'xrp-body-italic';
    } else {
      className += 'xrp-heading-italic';
    }
  }

  if (underline) {
    className += 'xrp-underline';
  }

  const Wrapper = code ? 'code' : 'span';

  const wrapperProps = {};
  if (subscript) {
    wrapperProps.sub = true;
  }
  if (superscript) {
    wrapperProps.sup = true;
  }
  if (overflow) {
    className += 'text-overflow';
  }
  if (backgroundColor || color) {
    wrapperProps.style = { backgroundColor, color };
  }
  if (commentId) {
    if (activeComment) {
      className += ' layout-leaf-comment-active';
    } else {
      className += ' layout-leaf-comment';
    }
    customAttributes = {
      ...customAttributes,
      'comment-id': commentId,
    };
  }
  return (
    <Wrapper {...customAttributes} className={className} {...wrapperProps}>
      {children}
    </Wrapper>
  );
};
