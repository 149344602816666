import { i18n } from 'app/utils/i18n';
import { useCallback, useContext, useMemo, useRef, useState, useEffect } from 'react';
import { useEditorSelector, EditorContext } from 'app/state/contexts/EditorContext';
import { UserContext } from 'app/state/contexts';
import { useSaveStyle, useStyles } from 'app/api/style-queries';
import { setDocument as setReduxDocument } from 'app/state/redux/documentSlice';

import borderRadiusTopLeft from 'app/icons/border-radius-top-left.svg';
import borderRadiusTopRight from 'app/icons/border-radius-top-right.svg';
import borderRadiusBottomLeft from 'app/icons/border-radius-bottom-left.svg';
import borderRadiusBottomRight from 'app/icons/border-radius-bottom-right.svg';

import seperateSettings from 'app/icons/separate-settings.svg';
import spacingHorizontal from 'app/icons/spacing-horizontal.svg';
import spacingVertical from 'app/icons/spacing-vertical.svg';
import spacingBottom from 'app/icons/spacing-bottom.svg';
import spacingTop from 'app/icons/spacing-top.svg';
import spacingRight from 'app/icons/spacing-right.svg';
import spacingLeft from 'app/icons/spacing-left.svg';

import borderThickness from 'app/icons/border-thickness.svg';
import Dropdown from 'app/components/Dropdown';
import Tippy from '@tippyjs/react';

import { unWrapStyledParagraph, toggleConnect } from 'app/slate/extensions/styled-paragraph';
import { useSlate } from 'slate-react';

import { useEditorDispatch } from 'app/state/contexts/EditorContext';
import { useDelayedSave } from 'app/utils/hooks/delayed-save';
import { ColorPicker } from 'app/components/editor/subtoolbar/ColorButton';
import cloneDeep from 'lodash.clonedeep';
import { SidebarContext } from 'app/state/contexts/SidebarContext';

export const StyledParagraph = () => {
  const { pDocument } = useEditorSelector((editor) => editor);
  const activeSection = useEditorSelector((editor) => editor.activeSection);
  const isDirty = useEditorSelector((editor) => editor.isDirty);

  const editor = useSlate();
  const graphicalStyleId = pDocument.content.design.find((d) => d.type === 'graphical-variables')?.styleId;
  const pageStyleId = pDocument.content.design.find((d) => d.pageId === activeSection.uuid)?.styleId;
  const { selectedOrganization } = useContext(UserContext);
  const { shadowHost } = useContext(EditorContext);
  const { styledParagraph: styledParagraphNode, setStyledParagraph } = useContext(SidebarContext);
  const editorDispatch = useEditorDispatch();
  const { data: stylesData = [] } = useStyles({
    organization: selectedOrganization,
    documentSuperId: pDocument.superId,
    styleIds: [pageStyleId, graphicalStyleId],
  });
  const [pageStyle, graphicalStyle] = stylesData;

  const savedStyle = pageStyle?.content;

  const { mutate: saveStyle } = useSaveStyle({
    organization: selectedOrganization,
    documentSuperId: pDocument.superId,
    pageId: activeSection.uuid,
    templateSuperId: activeSection.templateSuperId,
  });
  const [localStyle, setLocalStyle] = useState(null);
  const style = localStyle ?? savedStyle;
  const latestStyleRef = useRef(style);
  const nodeStyle = style?.[`xrp-super-id-${styledParagraphNode?.id}`];
  const paragraphElement = shadowHost.shadowRoot.querySelector(`.xrp-super-id-${styledParagraphNode?.id}`);
  const paragraphDimensions = useMemo(() => paragraphElement?.getBoundingClientRect(), [paragraphElement]);
  useEffect(() => {
    latestStyleRef.current = style;
  }, [style]);
  const colors = useMemo(
    () =>
      Object.keys(COLOR_MAP).map((key) => {
        return {
          name: key,
          title: i18n(COLOR_MAP[key].label),
          value: graphicalStyle?.content[key],
          icon: <div style={{ height: '10px', width: '10px', backgroundColor: graphicalStyle?.content[key] }} />,
        };
      }),
    [graphicalStyle?.content]
  );
  const backgroundColorExists = useMemo(() => colors.some((color) => color.value === nodeStyle?.['background-color']), [colors, nodeStyle]);
  const borderColorExists = useMemo(() => colors.some((color) => color.value === nodeStyle?.['border-color']), [colors, nodeStyle]);
  const resetValues = (keys) => {
    const classKey = `xrp-super-id-${styledParagraphNode.id}`;
    const newStyle = { ...style };
    if (!newStyle[classKey]) {
      newStyle[classKey] = {};
    }
    console.log('newstyle', newStyle[classKey]);
    if (keys && keys.length > 0) {
      keys.forEach((key) => {
        console.log('deleting keys', key, newStyle[classKey][key]);
        delete newStyle[classKey][key];
      });
    } else {
      Object.keys(newStyle[classKey]).forEach((key) => {
        delete newStyle[classKey][key];
      });
    }

    paragraphElement.removeAttribute('style');
    setLocalStyle(newStyle);
    save();
  };

  const handleChange = (newProperties) => {
    const newStyle = cloneDeep(style) ?? {};
    const classKey = `xrp-super-id-${styledParagraphNode.id}`;
    if (!newStyle[classKey]) {
      newStyle[classKey] = {};
    }
    try {
      Object.entries(newProperties).forEach(([propertyKey, propertyValue]) => {
        const allowedStringInputs = ['background-color', 'border-color', 'border-style', 'position'];
        const isStringInput = allowedStringInputs.includes(propertyKey);

        if (
          propertyValue === undefined ||
          propertyValue === null ||
          propertyValue === '' ||
          propertyValue === 'mixe' ||
          (!isStringInput && isNaN(parseInt(propertyValue)))
        ) {
          delete newStyle[classKey][propertyKey];
        } else {
          if (isStringInput) {
            newStyle[classKey][propertyKey] = propertyValue;
          } else {
            const unit = propertyKey.includes('radius') ? '%' : 'px';
            newStyle[classKey][propertyKey] = { value: parseFloat(propertyValue), unit: unit };
          }
        }
      });

      paragraphElement.removeAttribute('style');
      setLocalStyle(newStyle);
      const cssProperties = {};
      Object.entries(newStyle[classKey]).forEach(([key, value]) => {
        if (typeof value === 'object' && value.value !== undefined && value.unit !== undefined) {
          cssProperties[key] = `${value.value === '' ? 0 : value.value}${value.unit}`;
        } else {
          cssProperties[key] = value;
        }
      });
      Object.entries(cssProperties).forEach(([key, value]) => {
        paragraphElement.style.setProperty(key, value);
      });
      save();
    } catch (e) {
      cancelSave();
      console.error('Error while updating style', styledParagraphNode?.id, newProperties, e);
    }
  };

  const saveSettings = () => {
    console.log('saveSettings', latestStyleRef.current);
    saveStyle(latestStyleRef.current, {
      onSuccess: (doc) => {
        editorDispatch(setReduxDocument(doc.data));
      },
    });
  };
  const [save, cancelSave] = useDelayedSave(saveSettings, 1500);

  const formatPlaceholder = useCallback(
    (key) => {
      let values = [];
      if (!nodeStyle) return;
      const filterStyles = (prefix, suffixes) =>
        Object.entries(nodeStyle)
          .filter(([k]) => k.startsWith(prefix) && suffixes.some((suffix) => k.endsWith(suffix)))
          .map(([, v]) => `${v.value}`);

      switch (key) {
        case 'border-radius':
          values = filterStyles('border', ['radius']);
          break;
        case 'margin-horizontal':
          values = filterStyles('margin', ['left', 'right']);
          break;
        case 'margin-vertical':
          values = filterStyles('margin', ['top', 'bottom']);
          break;
        case 'padding-horizontal':
          values = filterStyles('padding', ['left', 'right']);
          break;
        case 'padding-vertical':
          values = filterStyles('padding', ['top', 'bottom']);
          break;
        case 'border':
          values = filterStyles('border', ['width']);
          break;
        default:
          values = [];
          break;
      }

      if (values.length === 0) return '';
      const uniqueValues = new Set(values);
      return uniqueValues.size === 1 ? `${values[0]}` : 'mixed';
    },
    [nodeStyle]
  );

  const handleConnect = () => {
    if (!styledParagraphNode.connected) {
      handleChange({
        position: 'absolute',
      });
    }
    setStyledParagraph((prev) => ({
      ...prev,
      connected: !prev.connected,
    }));
    toggleConnect(editor, styledParagraphNode.id, nodeStyle);
  };

  if (!styledParagraphNode)
    return (
      <div className="preview--container" style={{ height: '100%' }}>
        <div className="px-3 pt-4 pb-3 w-100 bg-white sticky-top">
          <div className="fw-bold">{i18n('styledparagraph.sidebar.title')}</div>
        </div>
        <div className="styled-paragraph-wrapper px-3 no-paragraph">
          <p>{i18n('styledparagraph.sidebar.no-paragraph')}</p>
        </div>
      </div>
    );

  return (
    <div className="preview--container h-100">
      <div className="styled-paragraf-header ">
        <div className="fw-bold">{i18n('styledparagraph.sidebar.title')}</div>
        <div className="styled-paragraph-header-buttons">
          <Tippy content={<p className="gaby-tooltip arrow-top">{i18n('styledparagraph.reset-tooltip')}</p>} placement="bottom">
            <button className="not-a-button" onClick={resetValues}>
              <i className="fa-regular fa-trash"></i>
            </button>
          </Tippy>
          <Tippy content={<p className="gaby-tooltip arrow-top">{i18n('styledparagraph.remove-tooltip')}</p>} placement="bottom">
            <button
              className="not-a-button"
              onClick={() => {
                resetValues();
                unWrapStyledParagraph(editor, styledParagraphNode?.id);
              }}
            >
              <i className="fa-regular fa-x"></i>
            </button>
          </Tippy>
        </div>
      </div>
      <div className="styled-paragraph-wrapper px-3" style={{ height: '100%' }}>
        <div className="styled-paragraph-setting-wrapper">
          <p>{i18n('styledparagraph.sidebar.border-radius')}</p>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <img src={borderRadiusTopLeft} alt="Border radius icon" />
            <input
              placeholder={formatPlaceholder('border-radius')}
              type="text"
              pattern="[0-9]*"
              value={formatPlaceholder('border-radius') ?? ''}
              onChange={(e) => {
                handleChange({
                  'border-top-left-radius': e.target.value,
                  'border-top-right-radius': e.target.value,
                  'border-bottom-left-radius': e.target.value,
                  'border-bottom-right-radius': e.target.value,
                });
              }}
            />
            <Tippy
              content={<SettingsPopOver type="border-radius" values={nodeStyle} onChange={handleChange} />}
              placement="left"
              theme="light"
              interactive={true}
              trigger="click"
              appendTo="parent"
            >
              <img src={seperateSettings} alt="Seperate Settings Icon" className="settings-icon" />
            </Tippy>
          </div>
        </div>

        <div className="styled-paragraph-setting-wrapper">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p>{i18n('styledparagraph.sidebar.margin-title')}</p>
            <p>{i18n('styledparagraph.sidebar.margin-description')}</p>
          </div>
          <div style={{ gap: '8px' }} className="styled-paragraph-setting-wrapper">
            <div className="styled-paragraph-setting-inner">
              <div className="styled-paragraph-input-wrapper">
                <img src={spacingHorizontal} alt="Spacing Horizontal Icon" />
                <input
                  placeholder={formatPlaceholder('margin-horizontal')}
                  type="text"
                  pattern="[0-9]*"
                  value={formatPlaceholder('margin-horizontal') ?? ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange({ 'margin-right': value, 'margin-left': value });
                  }}
                />
              </div>
              <div className="styled-paragraph-input-wrapper">
                <img src={spacingVertical} alt="Spacing Vertical Icon" />
                <input
                  placeholder={formatPlaceholder('margin-vertical')}
                  type="text"
                  pattern="[0-9]*"
                  value={formatPlaceholder('margin-vertical') ?? ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange({
                      'margin-top': value,
                      'margin-bottom': value,
                    });
                  }}
                />
              </div>
            </div>
            <Tippy
              content={<SettingsPopOver type="margin" values={nodeStyle} onChange={handleChange} />}
              placement="left"
              theme="light"
              interactive={true}
              trigger="click"
              appendTo="parent"
            >
              <img src={seperateSettings} alt="Seperate Settings Icon" className="settings-icon" />
            </Tippy>
          </div>
        </div>
        <div className="styled-paragraph-setting-wrapper">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p>{i18n('styledparagraph.sidebar.padding-title')}</p>
            <p>{i18n('styledparagraph.sidebar.padding-description')}</p>
          </div>
          <div style={{ gap: '8px' }} className="styled-paragraph-setting-wrapper">
            <div className="styled-paragraph-setting-inner">
              <div className="styled-paragraph-input-wrapper">
                <img src={spacingHorizontal} alt="Spacing Horizontal Icon" />
                <input
                  placeholder={formatPlaceholder('padding-horizontal')}
                  type="text"
                  pattern="[0-9]*"
                  value={formatPlaceholder('padding-horizontal') ?? ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange({
                      'padding-left': value,
                      'padding-right': value,
                    });
                  }}
                />
              </div>
              <div className="styled-paragraph-input-wrapper">
                <img src={spacingVertical} alt="Spacing Vertical Icon" />
                <input
                  placeholder={formatPlaceholder('padding-vertical')}
                  type="text"
                  pattern="[0-9]*"
                  value={formatPlaceholder('padding-vertical') ?? ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange({
                      'padding-top': value,
                      'padding-bottom': value,
                    });
                  }}
                />
              </div>
            </div>
            <Tippy
              content={<SettingsPopOver type="padding" values={nodeStyle} onChange={handleChange} />}
              placement="left"
              theme="light"
              interactive={true}
              trigger="click"
              appendTo="parent"
            >
              <img src={seperateSettings} alt="Seperate Settings Icon" className="settings-icon" />
            </Tippy>
          </div>
        </div>
        <div className="divider horizontal bg-blue-500" />
        <div>
          <p className="mb-r-1">{i18n('styledparagraph.sidebar.background-color')}</p>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '16px' }}>
            <Dropdown
              items={colors}
              sm
              value={backgroundColorExists ? nodeStyle?.['background-color'] : null}
              placeHolder={backgroundColorExists ? '' : i18n('styledparagraph.custom-color')}
              onChange={(e) => {
                handleChange({ 'background-color': e });
              }}
              key="background-color-dropdown"
            />
            <Tippy
              content={<ColorPicker handleChangeCallBack={(color) => handleChange({ 'background-color': color.hex })} key="background-color-picker" />}
              placement="left-start"
              theme="light"
              interactive={true}
              trigger="click"
              appendTo="parent"
            >
              <div className="styled-paragraph-color-icon" style={{ background: nodeStyle?.['background-color'] ?? 'transparent' }} />
            </Tippy>
          </div>
        </div>
        <div className="divider horizontal bg-blue-500" />
        <div>
          <p className="mb-r-1">{i18n('styledparagraph.sidebar-border')}</p>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '16px' }}>
            <Dropdown
              items={colors}
              sm
              value={borderColorExists ? nodeStyle?.['border-color'] : null}
              placeHolder={borderColorExists ? '' : i18n('styledparagraph.custom-color')}
              onChange={(e) => handleChange({ 'border-color': e, 'border-style': 'solid' })}
              key="border-color-dropdown"
            />
            <Tippy
              content={
                <ColorPicker handleChangeCallBack={(color) => handleChange({ 'border-color': color.hex, 'border-style': 'solid' })} key="border-color-picker" />
              }
              placement="left-start"
              theme="light"
              interactive={true}
              trigger="click"
              appendTo="parent"
            >
              <div className="styled-paragraph-color-icon" style={{ background: nodeStyle?.['border-color'] ?? '#000000' }} />
            </Tippy>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', placeSelf: 'end', marginTop: '16px' }}>
            <img src={borderThickness} alt="Border Thickness Icon" />
            <input
              placeholder={formatPlaceholder('border')}
              type="text"
              pattern="[0-9]*"
              style={{ marginLeft: '16px', marginRight: '8px' }}
              value={formatPlaceholder('border') ?? ''}
              onChange={(e) => {
                const value = e.target.value;
                handleChange({
                  'border-top-width': value,
                  'border-right-width': value,
                  'border-bottom-width': value,
                  'border-left-width': value,
                  'border-style': value ? 'solid' : 'none',
                });
              }}
            />
            <Tippy
              content={
                <SettingsPopOver
                  type="border"
                  values={nodeStyle}
                  onChange={(value) =>
                    handleChange({
                      'border-width': 0,
                      'border-style': value ? 'solid' : 'none',
                      ...value,
                    })
                  }
                />
              }
              placement="left"
              theme="light"
              interactive={true}
              trigger="click"
              appendTo="parent"
            >
              <img src={seperateSettings} alt="Seperate Settings Icon" className="settings-icon" />
            </Tippy>
          </div>
        </div>
        <div className="divider horizontal bg-blue-500" />
        <div className="mb-r-1 d-flex flex-row justify-content-between align-items-center">
          <p>{i18n('styledparagraph.position-title')}</p>
          <Tippy content={<p className="gaby-tooltip arrow-top">{i18n('styledparagraph.reset-position-tooltip')}</p>} placement="bottom">
            <button
              className="not-a-button"
              onClick={() => {
                resetValues(['position', 'top', 'left']);
              }}
            >
              <i className="fa-regular fa-trash"></i>
            </button>
          </Tippy>
        </div>
        <div className="styled-paragraph-setting-wrapper">
          <p>{i18n('styledparagraph.position-width')}</p>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <input
              placeholder={nodeStyle?.['width']?.value ?? Math.round(paragraphDimensions?.width)}
              type="text"
              pattern="[0-9]*"
              value={nodeStyle?.['width']?.value ?? Math.round(paragraphDimensions?.width)}
              onChange={(e) => {
                handleChange({
                  width: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <div className="styled-paragraph-setting-wrapper">
          <p>{i18n('styledparagraph.position-height')}</p>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <input
              placeholder={nodeStyle?.['height']?.value ?? Math.round(paragraphDimensions?.height)}
              type="text"
              pattern="[0-9]*"
              value={nodeStyle?.['height']?.value ?? Math.round(paragraphDimensions?.height)}
              onChange={(e) => {
                handleChange({
                  height: e.target.value,
                });
              }}
            />
          </div>
        </div>

        <button onClick={handleConnect} className="btn btn-secondary" disabled={isDirty}>
          {isDirty ? (
            'Sparar ...'
          ) : (
            <span className="d-flex flex-row gap-2 justify-content-center align-items-center">
              {!styledParagraphNode.connected ? (
                <>
                  {i18n('styledparagraph.element-release')}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="15" height="19" fill="currentColor">
                    <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L481.4 352c9.8-.4 18.9-5.3 24.6-13.3c6-8.3 7.7-19.1 4.4-28.8l-1-3c-13.8-41.5-42.8-74.8-79.5-94.7L418.5 64 448 64c17.7 0 32-14.3 32-32s-14.3-32-32-32L192 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l29.5 0-6.1 79.5L38.8 5.1zM324.9 352L177.1 235.6c-20.9 18.9-37.2 43.3-46.5 71.3l-1 3c-3.3 9.8-1.6 20.5 4.4 28.8s15.7 13.3 26 13.3l164.9 0zM288 384l0 96c0 17.7 14.3 32 32 32s32-14.3 32-32l0-96-64 0z" />
                  </svg>
                </>
              ) : (
                <>
                  {i18n('styledparagraph.element-attach')}
                  <i className="fa-solid fa-thumbtack"></i>
                </>
              )}
            </span>
          )}
        </button>
      </div>
    </div>
  );
};

const SettingsPopOver = ({ type, onChange, values }) => {
  const settingsType = type === 'border-radius' ? 'radius' : 'spacing';

  const formatKey = (type, key) => {
    let cssProperty;
    switch (type) {
      case 'margin':
      case 'padding':
        cssProperty = `${type}-${key}`;
        break;
      case 'border':
        cssProperty = `border-${key}-width`;
        break;
      case 'border-radius':
        cssProperty = `border-${key}-radius`;
        break;
      default:
        break;
    }

    return values?.[cssProperty]?.value ?? '';
  };

  const handleSettingsChange = (value, key) => {
    let cssProperty;
    switch (type) {
      case 'margin':
      case 'padding':
        cssProperty = { [`${type}-${key}`]: value };
        break;
      case 'border':
        cssProperty = { [`border-${key}-width`]: value };
        break;
      case 'border-radius':
        cssProperty = { [`border-${key}-radius`]: value };
        break;
      default:
        break;
    }
    onChange(cssProperty);
  };

  return (
    <div className="seperate-setting-wrapper">
      <div className="seperate-setting-inner">
        <div>
          <img src={settingsType === 'spacing' ? spacingTop : borderRadiusTopLeft} alt="Spacing Top Icon" />
          <input
            placeholder="0"
            type="text"
            pattern="[0-9]*"
            value={formatKey(type, settingsType === 'spacing' ? 'top' : 'top-left')}
            onChange={(e) => handleSettingsChange(e.target.value, settingsType === 'spacing' ? 'top' : 'top-left')}
          />
        </div>
        <div>
          <img src={settingsType === 'spacing' ? spacingRight : borderRadiusTopRight} alt="Spacing Right Icon" />
          <input
            placeholder="0"
            type="text"
            pattern="[0-9]*"
            value={formatKey(type, settingsType === 'spacing' ? 'right' : 'top-right')}
            onChange={(e) => handleSettingsChange(e.target.value, settingsType === 'spacing' ? 'right' : 'top-right')}
          />
        </div>
      </div>
      <div className="seperate-setting-inner">
        <div>
          <img src={settingsType === 'spacing' ? spacingBottom : borderRadiusBottomLeft} alt="Spacing Top Icon" />
          <input
            placeholder="0"
            type="text"
            pattern="[0-9]*"
            value={formatKey(type, settingsType === 'spacing' ? 'bottom' : 'bottom-left')}
            onChange={(e) => handleSettingsChange(e.target.value, settingsType === 'spacing' ? 'bottom' : 'bottom-left')}
          />
        </div>
        <div>
          <img src={settingsType === 'spacing' ? spacingLeft : borderRadiusBottomRight} alt="Spacing Right Icon" />
          <input
            placeholder="0"
            type="text"
            pattern="[0-9]*"
            value={formatKey(type, settingsType === 'spacing' ? 'left' : 'bottom-right')}
            onChange={(e) => handleSettingsChange(e.target.value, settingsType === 'spacing' ? 'left' : 'bottom-right')}
          />
        </div>
      </div>
    </div>
  );
};

const COLOR_MAP = {
  xrpPrimaryColor: {
    label: 'graphical-variable.xrpPrimaryColor',
  },
  xrpSecondaryColor: {
    label: 'graphical-variable.xrpSecondaryColor',
  },
  xrpTertiaryColor: {
    label: 'graphical-variable.xrpTertiaryColor',
  },
  xrpQuaternaryColor: {
    label: 'graphical-variable.xrpQuaternaryColor',
  },
  xrpHeadingColor: {
    label: 'graphical-variable.xrpHeadingColor',
  },
};
