import { forwardRef, useRef, useState } from 'react';
import { useUsersWithSectionPermission } from '../api/auth/user-queries';
import { useEditorSelector } from 'app/state/contexts/EditorContext';

export const MentionInput = forwardRef(({ value, onChange, placeHolder }, ref) => {
  const [showMentions, setShowMentions] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const itemRefs = useRef([]);
  const { superId } = useEditorSelector((editor) => editor.pDocument);
  const { superId: sectionSuperId } = useEditorSelector((editor) => editor.section);

  const { data: usersWithAccess } = useUsersWithSectionPermission(superId, sectionSuperId);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const handleInputChange = (inputValue) => {
    onChange(inputValue);

    const mentionMatch = inputValue.match(/@(\w*)$/);
    if (mentionMatch) {
      const search = mentionMatch[1].toLowerCase();
      const matchedUsers = usersWithAccess.filter((user) => user.userName.toLowerCase().startsWith(search));

      setFilteredUsers(matchedUsers);
      setShowMentions(matchedUsers.length > 0);
    } else {
      setFilteredUsers([]);
      setShowMentions(false);
    }
  };

  const handleUserSelect = (user, event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const newValue = value.replace(/@(\w*)$/, `@${user?.firstName}${user?.lastName}`);
    onChange(newValue.trim());
    setShowMentions(false);
  };

  const handleKeyDown = (e) => {
    if (!showMentions) return;

    if (e.key === 'ArrowDown') {
      setHighlightedIndex((prev) => {
        const newIndex = (prev + 1) % filteredUsers.length;
        itemRefs.current[newIndex]?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        return newIndex;
      });
    } else if (e.key === 'ArrowUp') {
      setHighlightedIndex((prev) => {
        const newIndex = (prev - 1 + filteredUsers.length) % filteredUsers.length;
        itemRefs.current[newIndex]?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        return newIndex;
      });
    } else if (e.key === 'Enter' && highlightedIndex !== -1) {
      handleUserSelect(filteredUsers[highlightedIndex], e);
    }
  };

  return (
    <div className="mention-wrapper">
      <textarea
        ref={ref ?? null}
        placeholder={placeHolder}
        value={value}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={(e) => handleInputChange(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      {showMentions && (
        <div className="comment-mention-list">
          {filteredUsers.length > 0 ? (
            filteredUsers.map((user, idx) => (
              <div
                key={user.id}
                ref={(el) => (itemRefs.current[idx] = el)}
                className={`${idx === highlightedIndex ? 'bg-blue-500' : ''} comments-mention-list-item`}
                onClick={(e) => {
                  handleUserSelect(user, e);
                }}
              >
                {user.userName}
              </div>
            ))
          ) : (
            <div className="comment-no-mention">No users found</div>
          )}
        </div>
      )}
    </div>
  );
});
